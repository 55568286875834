import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import RenderRichText from 'utils/richText/renderRichText';

import { StyledTextList, H3 } from 'styled';

const isSwissDomain = process.env.IS_SWISS_DOMAIN;

import { 
  StyledPurpleWidgetWrapper, 
  StyledPurpleWidget,
  StyledPhoneNumberLink,
  StyledImageWidget, 
  StyledCardTitle,
  StyledKeyFeaturesText
} from 'components/widgets/styled';

function SideBarWidget({ 
  widgetType, 
  widgetData, 
  buttonComponent, 
  preview, 
  pageType,
  isAircraftTemplate, 
  keyFeaturesText,
  aircraftName,
  hasPurpleCTAWidgetBelow,
  isFrench,
  swissPhoneNumber,
}) {

  const [ widgetPosition, setWidgetPosition] = useState(0);

  const phoneNumber = isSwissDomain && swissPhoneNumber ? swissPhoneNumber : widgetData && widgetData.phone_number;
  // On pages such as Jet Card where phone number comes from Prismic remove the (0) from the phone number 102990
  const hrefPhoneNumber = phoneNumber && phoneNumber.replace(/\+44\s\(0\)/g, '+44');

  // check if widget text is key features, preview or normal jet card widget text
  const widgetTextArray = widgetType === "key features" ? widgetData?.widget_content?.raw 
    : preview ? widgetData.widget_text 
    : widgetData.widget_text.raw;

  useLayoutEffect(() => {
    window.addEventListener('resize', handleWidgetPosition);
    handleWidgetPosition()
  }, [])

  const handleWidgetPosition = () => {
    const bannerElement = document.querySelector("#top-banner-image-container");
    const headerElement = document.querySelectorAll(".header")[0];

    const bannerOffsetHeight = bannerElement && bannerElement.offsetHeight;
    const headerOffsetHeight = headerElement && headerElement.offsetHeight;

    setWidgetPosition(bannerOffsetHeight + headerOffsetHeight)
  }

  // TODO refactor this once tested
  if (isAircraftTemplate && (widgetTextArray || aircraftName || preview )) { 
    return (
      <StyledPurpleWidget 
        isAircraftTemplate={true} 
        hasPurpleCTAWidgetBelow={hasPurpleCTAWidgetBelow}
      >
        <StyledKeyFeaturesText>
          { widgetTextArray ? (
            <RenderRichText richTextArray={widgetTextArray} />
          ) : keyFeaturesText ? (
            <React.Fragment>
              <H3>{isFrench ? "Atouts du" : "Key features of the"} {aircraftName}</H3>
              <StyledTextList dangerouslySetInnerHTML={{__html: keyFeaturesText }} />
            </React.Fragment>
          ) : preview && widgetData && (
            <RenderRichText richTextArray={widgetData.widget_content} />
          )
        }
        </StyledKeyFeaturesText>
      </StyledPurpleWidget>
    )

  } else if (widgetType === "purple call to action widget") {
    const isPageTypeProduct = pageType === "product";

    if(isPageTypeProduct) {
      return (
        <StyledPurpleWidgetWrapper widgetPosition={widgetPosition}>
          <StyledPurpleWidget isPageTypeProduct={true} isFrench={isFrench}>
            <RenderRichText richTextArray={widgetTextArray} />
            {buttonComponent}
            { phoneNumber && (
              <StyledPhoneNumberLink isPageTypeProduct={true} href={`tel:${hrefPhoneNumber}`}>
                {phoneNumber}
              </StyledPhoneNumberLink>
            )}
          </StyledPurpleWidget>
        </StyledPurpleWidgetWrapper>
      )
    } else {
      return (
        <StyledPurpleWidget isPageTypeProduct={false}>
          <RenderRichText richTextArray={widgetTextArray} />
          {buttonComponent}
          { phoneNumber && (
            <StyledPhoneNumberLink isPageTypeProduct={false} href={`tel:${hrefPhoneNumber}`}>
              {phoneNumber}
            </StyledPhoneNumberLink>
          )}
        </StyledPurpleWidget>
      )
    }
  
  } else if (widgetType === "image card widget") {
    const imageCardTitle = widgetData.widget_title.text ? <StyledCardTitle>{widgetData.widget_title.text}</StyledCardTitle> : null;

    const cardImg = widgetData.card_image.url ? widgetData.card_image.url : "";

    const isPageTypeProduct = pageType === "product";

    return (
      <StyledImageWidget>
        <img src={cardImg} style={{marginBottom: '33px', width: '100%'}} />
        {imageCardTitle}
        <RenderRichText richTextArray={widgetTextArray} />
        <div style={{marginTop: '25px'}}> {buttonComponent} </div>
        { phoneNumber && (
          <StyledPhoneNumberLink isPageTypeProduct={isPageTypeProduct} href={`tel:${phoneNumber}`}>
            {phoneNumber}
          </StyledPhoneNumberLink>
        )}
      </StyledImageWidget>
    )
  }

}

SideBarWidget.propTypes = {
  aircraftName: PropTypes.string,
  buttonComponent: PropTypes.object,
  hasPurpleCTAWidgetBelow: PropTypes.bool,
  isAircraftTemplate: PropTypes.bool,
  isFrench: PropTypes.bool,
  keyFeaturesText: PropTypes.string,
  pageType: PropTypes.string,
  preview: PropTypes.bool,
  swissPhoneNumber: PropTypes.string,
  widgetData: PropTypes.object,
  widgetType: PropTypes.string
}

export default SideBarWidget

