import styled from 'styled-components';

export const StyledContentNavigationWrapper = styled.nav`
  position: relative;
  display: ${props => props.isTwoColumnLayout ? "block" : "none"};
  border-bottom: 2px solid #efefef;
  overflow: hidden;
  overflow-x: scroll;
  margin-bottom: ${props => props.marginBottom && props.marginBottom};

  @media(min-width: 480px) {
    overflow: unset;
    overflow-x: unset;
  }

  @media(min-width: 768px) {
    display: block;
  } 
`
export const StyledContentNavigation = styled.nav`
  position: relative;
  display: flex;
  width: 100%;

  @media(min-width: 1024px) {
    width: ${props => props.isFrench ? "65%" : "100%"};
  }

  ul {
    display: flex;
    width: 100%;
    margin-bottom: 0;
    list-style: none;
    padding: 0;
    flex-wrap: nowrap;

    @media(min-width: 480px) {
      margin-bottom: revert;
    }
    
    li {
      position: relative;
      width: 100%;
      margin-bottom: 15px;
      cursor: pointer;
      min-width: max-content;
      margin-right: 40px;
      
      &:last-child {
        padding-right: 25px;
      } 
      
      @media(min-width: 480px) {
        width: initial;
        flex-wrap: nowrap;
        margin-right: 40px;
        margin-bottom: 0;
        min-width: unset;
        
        &:last-child {
          padding-right: 0px;
        } 
      }

      @media(min-width: 480px) {
        &::after {
          content: "";
          position: absolute;
          bottom: -16px;
          left: 0;
          width: 100%;
          height: 2px;
          background: transparent;
          transition: 500ms;
          z-index: 15;
        }

        &:hover::after {
          background: #E30613;
        }
      }

    }

    a {
      color: #000;
      font-weight: 600;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 0.93px;
    }

    a:hover {
      text-decoration: none;
    }
  }
`