import React from 'react';

const PassengerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24px" height="24px"
      viewBox="0 0 24 24" version="1.1"
    >
      <title>85E88B84-26B0-4836-ACD8-98EB5DF0A84E</title>
      <g id="Icons" stroke="none"
        stroke-width="1" fill="#B4B5B4"
        fill-rule="evenodd"
      >
        <g id="Iconography" transform="translate(-117.000000, -208.000000)"
          fill="#B4B5B4"
        >
          <g id="Passengers" transform="translate(117.000000, 208.000000)">
            <path d="M12,9 L12,10 L11.9945911,10.7106061 C11.9325099,14.6277724 11.3219733,16.754933 9.44205895,17.726055 L9.26764068,17.8111718 L9.142,17.865 L9.14285714,24 L2.85714286,24 L2.857,17.865 L2.73235932,17.8111718 C0.71298067,16.8810327 0.0677383648,14.7410678 0.00516893217,10.6952771 L0.00114008973,10.333332 L0,9 L12,9 Z M9.988,11 L2.011,11 L2.03042845,11.5800674 L2.06267657,12.1624568 C2.22895228,14.583818 2.74624932,15.69079 3.85173895,16.1016641 L3.98191926,16.1460186 L4.10877671,16.1821775 L4.85714286,16.3767527 L4.857,22 L7.142,22 L7.14285714,16.3767527 L7.89122329,16.1821775 C9.17498213,15.8484002 9.7589098,14.7637864 9.93743941,12.1607667 L9.95502973,11.8760266 L9.98122466,11.2708457 L9.988,11 Z M20,7 L20,11.001 L24.001,11.001 L24.001,13.001 L20,13.001 L20,17 L18,17 L18,13.001 L14,13.001 L14,11.001 L18,11.001 L18,7 L20,7 Z M5.93333333,0 C8.10481808,0 9.86666667,1.76184858 9.86666667,3.93333333 C9.86666667,6.10481808 8.10481808,7.86666667 5.93333333,7.86666667 C3.76184858,7.86666667 2,6.10481808 2,3.93333333 C2,1.76184858 3.76184858,0 5.93333333,0 Z M5.93333333,2 C4.86641808,2 4,2.86641808 4,3.93333333 C4,5.00024858 4.86641808,5.86666667 5.93333333,5.86666667 C7.00024858,5.86666667 7.86666667,5.00024858 7.86666667,3.93333333 C7.86666667,2.86641808 7.00024858,2 5.93333333,2 Z" id="Fill" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default PassengerIcon