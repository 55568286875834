import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby";

import SpecificationsList from 'components/aircraft/AircraftCardsSection/SpecificationsList';
import ButtonSection from 'components/aircraft/AircraftCardsSection/ButtonSection';

import { FullWidthSmallCardsContainer } from 'components/grid/styled';

function AircraftCardsSection({ 
  aircraftData, 
  isCarousel, 
  category, 
  buttonAlign, 
  allAircraft, 
  buttonUrl, 
  isAircraftCategoryPage, 
  handleCardsPagination, 
  cardsToShow, 
  categoryUrl,
  preview,
  language
}) {

  const data = useStaticQuery(graphql`
    query AircraftListQuery {
      allAircraftSpecs {
        edges {
          node {
            ...AircraftSpecs
          }
        }
      }
    }
  `)

  let array = [];
  let aircraftFilteredByCategory = [];
  let getLength = [];

  aircraftData.filter(el => {
    return data.allAircraftSpecs.edges.filter(elem => {
      if (Number(el.id) === elem.node.specs_list.id && !category) {
        array.push({
          id: el.id,
          cardImage: el.cardImage,
          aircraftUrl: el.aircraftUrl,
          type: elem.node.specs_list.aircraft_type,
          speed: elem.node.specs_list.cruising_speed,
          range: elem.node.specs_list.cruising_range,
          passengers: elem.node.specs_list.number_of_passenger_seat,
          category: elem.node.specs_list.title,
        })
      } else if (Number(el.id) === elem.node.specs_list.id && category === elem.node.specs_list.title) {
        array.push({
          id: el.id,
          cardImage: el.cardImage,
          aircraftUrl: el.aircraftUrl,
          type: elem.node.specs_list.aircraft_type,
          speed: elem.node.specs_list.cruising_speed,
          range: elem.node.specs_list.cruising_range,
          passengers: elem.node.specs_list.number_of_passenger_seat,
          category: elem.node.specs_list.title,
        })
      }
    })
  });

  if (allAircraft) {
    allAircraft.filter(el => {
      return data.allAircraftSpecs.edges.filter(elem => {
        
        const aircraftId = preview ? el.aircraft_id : el.node.data.aircraft_id
        
        if (elem.node.specs_list.id === Number(aircraftId) ) {
          getLength.push({
            category: elem.node.specs_list.title,
            id: aircraftId,
            aircraftUrl: el.aircraftUrl,
          })
        }
      })
    })

    aircraftFilteredByCategory = getLength.filter(el => el.category === category);
  }

  return (
    <React.Fragment>
      {isCarousel === true ? 
        (
          <SpecificationsList 
            array={array} 
            isCarousel={isCarousel}
            categoryUrl={categoryUrl}
            language={language}
          />
        )
      : 
      (
        <FullWidthSmallCardsContainer isCarousel={isCarousel}>
          <SpecificationsList 
            array={array} 
            cardsToShow={cardsToShow}
            categoryUrl={categoryUrl}
            language={language}
          />
          <ButtonSection 
            preview={preview}
            buttonAlign={buttonAlign}
            isAircraftCategoryPage={isAircraftCategoryPage}
            cardsToShow={cardsToShow}
            array={array}
            aircraftFilteredByCategory={aircraftFilteredByCategory}
            category={category}
            buttonUrl={buttonUrl}
            handleCardsPagination={handleCardsPagination}
            language={language}
          />
        </FullWidthSmallCardsContainer>
      )}
    </React.Fragment>
  )
}

AircraftCardsSection.propTypes = {
  aircraftData: PropTypes.array,
  allAircraft: PropTypes.array,
  buttonAlign: PropTypes.string,
  buttonUrl: PropTypes.string,
  cardsToShow: PropTypes.number,
  category: PropTypes.string,
  categoryUrl: PropTypes.string,
  handleCardsPagination: PropTypes.func,
  isAircraftCategoryPage: PropTypes.bool,
  isCarousel: PropTypes.bool,
  language: PropTypes.string,
  preview: PropTypes.bool
}

export default AircraftCardsSection

