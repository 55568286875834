import React from 'react'
import PropTypes from 'prop-types'

import MagnifyGlassIcon from "svgs/magnifyGlassIcon"

import { StyledButton, StyledButtonLink } from 'components/buttons/Main/styled';

function Button({ 
  buttonText,
  buttonHref, 
  theme, 
  isLink, 
  size, 
  padding, 
  margin, 
  fontSize,
  width,
  className,
  onClick,
  isZoomButton,
  language
}) {
  if (isLink) {
    return (
      <StyledButtonLink 
        size={size}
        padding={padding}
        margin={margin}
        fontSize={fontSize}
        width={width}
        path={buttonHref} 
        language={language ? language : "en-gb"}
        linkText={buttonText} 
        className={className}
        isZoomButton={isZoomButton}
      />
    ) 
  } else {
    return (
      <StyledButton 
        theme={theme} 
        size={size}
        padding={padding}
        margin={margin}
        fontSize={fontSize}
        width={width}
        className={className}
        onClick={onClick}
        isZoomButton={isZoomButton}
      >
        {buttonText} 
        {
          isZoomButton && <MagnifyGlassIcon />
        }
      </StyledButton>
    )
  }
 
}

Button.propTypes = {
  buttonHref: PropTypes.string, 
  buttonText: PropTypes.string, 
  className: PropTypes.string, 
  fontSize: PropTypes.string, 
  isLink: PropTypes.bool,
  isZoomButton: PropTypes.bool,
  margin: PropTypes.string,
  onClick: PropTypes.func,
  padding: PropTypes.bool,
  size: PropTypes.bool,
  theme: PropTypes.string,
  width: PropTypes.string,
}

export default Button

