import styled, { css } from 'styled-components'

export const StyledPurpleWidgetWrapper = styled.div`
  width: 100%;
  z-index: 10;

  @media(min-width: 1024px) {
    position: absolute;
    top: calc(${props => props.widgetPosition && `${props.widgetPosition}px - 200px`});
    left: 0;
    right: 0; 
    margin-left: auto;
    margin-right: auto;
    max-width: 1336px;
    padding: 0 40px;
  }
`

export const StyledKeyFeaturesText = styled.div`
  color: white;
  width: 100%;
  h3 { color: white; }
`;

export const StyledPurpleWidget = styled.div`
  display: ${props => props.isAircraftTemplate ? 'none' : 'flex'};
  flex-direction: column;
  width: 100%;
  background: #3C3C3B;
  padding: 20px;
  margin-top: ${props => !props.isPageTypeProduct && "30px"};
  z-index: 10;

  p {
    color: #FFFFFF;
    font-family: Raleway;
    line-height: unset;
  }

  .widget-cta-button {
    font-size: 14px;
    padding: 8px 18px;
  }

  @media(min-width: 600px) {
    max-width: ${props => props.isAircraftTemplate ? '100%' : !props.isPageTypeProduct && `400px`}; 
  }

  @media(min-width: ${props => props.isPageTypeProduct ? `1024px` : `1200px`}) {
    position: ${props => props.isPageTypeProduct && `absolute`}; 
    right: ${props => props.isPageTypeProduct && `40px`};
    display: flex;
    width: ${props => props.isAircraftTemplate ? '100%' : props.isPageTypeProduct && `400px`};
    height: ${props => props.isPageTypeProduct ? `250px` : `auto`};
    flex-direction: column;
    align-items: center;
    padding: ${props => props.isAircraftTemplate ? '20px' : '30px 25px 40px 25px'};
    margin-top: ${props => props.isPageTypeProduct && props.isFrench ? "50px" : "0px"};
    margin-bottom: ${props => props.hasPurpleCTAWidgetBelow && `20px`};
    max-width: ${props => props.isAircraftTemplate || !props.isPageTypeProduct && '100%' }; 

    p {
      font-size: 18px;
      margin-bottom: 37px;
    }

    .widget-cta-button {
      font-size: 18px;
      padding: 12px 15px;
    }

    h3 {
      ${props => 
        props.isAircraftTemplate &&
          css`
            color: white;
            font-size: 18px;
            font-weight: 600;
            text-align: left;
            margin: 0;
            margin-top: 0 !important;
          `
      }
    } 

    ul {
      ${props => 
        props.isAircraftTemplate &&
        css`
          padding-left: 0;
          list-style: none;
          li {
            position: relative;
            color: white;
            font-family: Raleway;
            font-size: 14px;
            font-weight: 500;
            &::before {
              content: "";
              display: inline-block;
              background-image :url("https://images.prismic.io/privatefly/f9969e12-fe1e-4d46-8fad-b252f704c63c_white-arrow.png?auto=compress,format");
              background-size: 18px 10px;
              width: 18px;
              height: 10px;
              margin-right: 20px;
            }
          }
        `
      }
    } 
  }
`

export const StyledPhoneNumberLink = styled.a`
  display: ${props => props.isPageTypeProduct ? `none` : `block`};
  margin-top: 20px; 
  font-family: Raleway;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;

  :hover {
    text-decoration: underline;
  }

  @media(min-width: 1024px) {
    display: block;
  }
`

export const StyledImageWidget = styled.div`
  width: 100%;
  padding: 20px;

  p {
    color: #636463;
    font-family: "Open Sans";
  }

  @media(min-width: 600px) {
    max-width: ${props => !props.isPageTypeProduct && `400px`};
  }

  @media(min-width: ${props => props.isPageTypeProduct ? `1024px` : `1200px`}) {
    display: flex;
    height: auto;
    flex-direction: column;
    padding: 30px 30px 40px 30px;
    max-width: ${props => !props.isPageTypeProduct && `100%`};
    
    /* TODO: test and remove */
    /* margin-top: 20px; */

    p {
      font-size: 14px;
      margin-bottom: 0px;
    }

    ul {
      padding-inline-start: inherit;
      color: #636463;
    }

    .widget-button {
      align-items: center;
      margin-top: 35px;
    }

  }
`

export const StyledCardTitle = styled.div`
  margin-bottom: 30px;
  font-family: Raleway;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 26px;
`;