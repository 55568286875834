import React from 'react';

const MagnifyGlassIcon = () => {
  return (
    <div className="maginfy-glass-calendar-svg">
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
        width="18px" height="18px"
        viewBox="0 0 24 24" version="1.1"
      >
        <title>E65FF8AB-92F4-4E65-A599-C0BCAAF6A554</title>
        <g id="Icons" stroke="none"
          stroke-width="1" fill="#FFF"
          fill-rule="evenodd"
        >
          <g id="Iconography" transform="translate(-225.000000, -262.000000)"
            fill="#FFF"
          >
            <g id="Search" transform="translate(225.000000, 262.000000)">
              <path d="M9.11123297,16.1977475 C7.21812123,16.1977475 5.43940608,15.4597376 4.10005484,14.1213987 C2.76272831,12.7830599 2.02471844,11.0043447 2.02471844,9.11123297 C2.02471844,7.21812123 2.76272831,5.43940608 4.10005484,4.10005484 C5.43940608,2.76272831 7.21812123,2.02471844 9.11123297,2.02471844 C11.0043447,2.02471844 12.7830599,2.76272831 14.1213987,4.10005484 C15.4597376,5.43940608 16.1977475,7.21812123 16.1977475,9.11123297 C16.1977475,11.0043447 15.4597376,12.7830599 14.1213987,14.1213987 C12.7830599,15.4597376 11.0043447,16.1977475 9.11123297,16.1977475 L9.11123297,16.1977475 Z M24,22.5685241 L16.2260936,14.7946176 C17.5178639,13.1859788 18.2224659,11.2037795 18.2224659,9.11123297 C18.2224659,6.67752141 17.2738854,4.38958957 15.5528747,2.6685789 C13.8328764,0.948580588 11.5449445,0 9.11123297,0 C6.67752141,0 4.38958957,0.948580588 2.6685789,2.6685789 C0.948580588,4.38958957 0,6.67752141 0,9.11123297 C0,11.5449445 0.948580588,13.8328764 2.6685789,15.5528747 C4.38958957,17.2738854 6.67752141,18.2224659 9.11123297,18.2224659 C11.2037795,18.2224659 13.1859788,17.5178639 14.7946176,16.2260936 L22.5685241,24 L24,22.5685241 Z" id="Fill" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default MagnifyGlassIcon