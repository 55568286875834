import React from 'react'
import PropTypes from 'prop-types'

import ArrowButton from 'components/buttons/ArrowButton';

import { StyledAircraftCategoryButton } from 'components/aircraft/AircraftCardsSection/styled';

const isFr = process.env.LANG_CODE==='fr-fr';

function ButtonSection({
  preview,
  buttonAlign,
  isAircraftCategoryPage,
  cardsToShow,
  array,
  aircraftFilteredByCategory,
  category,
  buttonUrl,
  handleCardsPagination,
  language
}) {
  if (isAircraftCategoryPage && cardsToShow < array.length) {
    return (
      <StyledAircraftCategoryButton 
        buttonAlign={buttonAlign} 
        isAircraftCategoryPage={isAircraftCategoryPage}
      >
        <ArrowButton 
          isLink={false}
          theme="red"
          buttonText={isFr ? 'VOIR PLUS' : 'LOAD MORE'}
          onClick={handleCardsPagination}
          padding="25px"
          language={language}
        />
      </StyledAircraftCategoryButton>
    )
  } else if (!isAircraftCategoryPage) {
    return (
      <StyledAircraftCategoryButton buttonAlign={buttonAlign}>
        <ArrowButton 
          isLink={true}
          theme="red"
          buttonText={`${isFr ? "VOIR LES" : "SEE ALL"} ${preview? '' : aircraftFilteredByCategory.length} ${category}`}
          buttonHref={buttonUrl ? buttonUrl : "/"}
          padding="25px"
          language={language}
        />    
      </StyledAircraftCategoryButton>
    )
  }
}

ButtonSection.propTypes = {
  aircraftFilteredByCategory: PropTypes.array,
  array: PropTypes.array,
  buttonAlign: PropTypes.string,
  buttonUrl: PropTypes.string,
  cardsToShow: PropTypes.number,
  category: PropTypes.string,
  handleCardsPagination: PropTypes.func,
  isAircraftCategoryPage: PropTypes.bool,
  language: PropTypes.string,
  preview: PropTypes.bool
}

export default ButtonSection

