import React from 'react';

const AircraftIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22px" height="25px"
      viewBox="0 0 22 25" version="1.1"
    >
      <title>47ACD67C-1156-4B4D-B09F-B448A4754186</title>
      <g id="Icons" stroke="none"
        stroke-width="1" fill="#B4B5B4"
        fill-rule="evenodd"
      >
        <g id="Iconography" transform="translate(-226.000000, -316.000000)"
          fill="#B4B5B4"
        >
          <g id="Jet" transform="translate(226.000000, 316.000000)">
            <path d="M10.8054571,0.00505502703 C8.90795611,0.105725723 7.41090812,1.69711386 7.44926192,3.61863948 L7.521,7.104 L0,10.8852635 L0,17.0310963 L7.688,15.202 L7.715,16.556 L5.95218182,17.8225067 L5.95218182,24.1817205 L10.996,21.754 L16.0408182,24.1817205 L16.0408182,17.8225067 L14.277,16.556 L14.304,15.202 L21.993,17.0310963 L21.993,10.8852635 L14.471,7.104 L14.54285,3.61984688 C14.5844664,1.63415668 12.9841751,0 10.9965,0 L10.8054571,0.00505502703 Z M10.9965,2 C11.8154192,2 12.4833513,2.63630275 12.53976,3.43600366 L12.5432727,3.57872727 L12.4456818,8.32440909 L19.993,12.1183636 L19.993,14.4994091 L12.3558636,12.6823182 L12.2556818,17.5670455 L14.0408182,18.8486818 L14.0408182,21 L10.9965,19.5352727 L7.95218182,21 L7.95218182,18.8486818 L9.73731818,17.5670455 L9.63713636,12.6823182 L2,14.4994091 L2,12.1183636 L9.54731818,8.32440909 L9.44886364,3.57872727 C9.43159091,2.71336364 10.1294091,2 10.9965,2 Z" id="Fill" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AircraftIcon