import styled, { css } from 'styled-components';

export const StyledAircraftSpecsList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 0;

  li {
    display: flex;
    align-items: center;
    margin-top: 20px;
    text-transform: uppercase;
    color: #000000;
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.93px;
    line-height: 11px;
  }

  span {
    margin-right: 15px;
  }

  img {
    height: 24px;
    width: 24px;
    margin-right: 15px;
  }
`

export const StyledAircraftCategoryButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${props => props.buttonAlign ? props.buttonAlign : "center"};
  margin-top: 20px;

  ${props => 
    props.isAircraftCategoryPage &&
      css`
        margin-bottom: 50px;
      `
  }

  ${props => 
    props.isAircraftIndexPage &&
      css`
        padding-bottom: 30px;
        border-bottom: 1px solid #efefef;
      `
  }

  ${props => 
    props.isAircraftIndexPage && props.lastOnIndexPage &&
      css`
        padding-bottom: 60px;
        border-bottom: none;
      `
  }

  a {
    width: 100%;
  }
  
  @media (min-width: 640px) {
    margin-top: -20px;
    margin-bottom: 0;
    display: ${props => props.isCarousel && "none"};

    a {
      width: initial;
    }
  }
`