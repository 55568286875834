import styled, { css } from 'styled-components';

import InternalLink from 'components/links/internalLink';

export const StyledButtonLink = styled(InternalLink)`
  display: block;
  width: fit-content;
  font-family: Raleway;
  cursor: pointer;

  ${props => 
    props.isZoomButton &&
      css`
        display: flex;
        justify-content: space-between;
        padding: 5px 5px !important;
      `
  }

  ${props => {
    if (props.size === "small") {
      return css`
        border: none;
        background: #46344E;
        color: white;
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
        padding: ${props => props.padding ? `6px ${props.padding}` : "6px 25px"};
      `
    } else if (props.size === "large") {
      return css`
        width: ${props => props.width ? props.width : "fit-content"};
        border: none;
        border-radius: 1px;
        background-color: #E30613;
        color: white;
        text-transform: uppercase;
        font-size: ${props => props.fontSize ? props.fontSize : "18px"};
        font-weight: 600;
        letter-spacing: 1.5px;
        line-height: 20px;
        text-align: center;
        padding: ${props => props.padding ? props.padding : "10px 20px"};
        margin: ${props => props.margin ? props.margin : "initial"};
      `
    }
  }}

  &:visited { 
    text-decoration: none; 
    color: #FFF;
  }

  &:hover { 
    text-decoration: none; 
    color: #FFF;
  }
`

export const StyledButton = styled.button`
 font-family: Raleway;
 cursor: pointer;

 ${props => 
    props.isZoomButton &&
      css`
        display: flex;
        justify-content: space-between;
        padding: 5px 5px !important;
      `
  }

  .maginfy-glass-calendar-svg {
    margin-left: 10px;
  }

  ${props => {
    if (props.size === "small") {
      return css`
        border: none;
        background: #46344E;
        color: white;
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
        padding: ${props => props.padding ? `6px ${props.padding}` : "6px 25px"};
      `
    } else if (props.size === "large") {
      return css`
        border: none;
        border-radius: 1px;
        background-color: #E30613;
        color: white;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 1.5px;
        line-height: 20px;
        padding: 10px 20px;
        margin: ${props => props.margin ? props.margin : "initial"}
      `
    }
  }}
`