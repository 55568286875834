import React from 'react'
import PropTypes from 'prop-types'

import { FullWidthContainer } from 'components/grid/styled';

import { StyledContentNavigation, StyledContentNavigationWrapper } from 'components/navigation/styled';

function ContentNavigation({ anchors, isTwoColumnLayout, marginBottom, isFrench }) {
  return (
    <StyledContentNavigationWrapper isTwoColumnLayout={isTwoColumnLayout} marginBottom={marginBottom}>
      <FullWidthContainer style={isFrench && {justifyContent: 'flex-start'}}>
        <StyledContentNavigation isFrench={isFrench}>
          <ul>
            {
              anchors.map(el => {
                const anchorLink = el.anchor_link || el.article_anchor_hash;
                const anchorText = el.anchor_link_text || el.article_anchor_text;

                return (
                  <li>
                    <a href={`#${anchorLink}`}>{anchorText}</a>
                  </li>
                )
              })
            }
          </ul>
        </StyledContentNavigation>
      </FullWidthContainer>
    </StyledContentNavigationWrapper>
  )
}

ContentNavigation.propTypes = {
  anchors: PropTypes.array,
  isFrench: PropTypes.bool,
  isTwoColumnLayout: PropTypes.bool,
  marginBottom: PropTypes.string,
}

export default ContentNavigation

