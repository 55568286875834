import React from 'react'
import PropTypes from 'prop-types'

import Card from 'components/card'

import PassengerIcon from "svgs/PassengerIcon"
import AircraftIcon from "svgs/AircraftIcon"
import LanguageIcon from "svgs/LanguageIcon"

import { StyledAircraftSpecsList } from 'components/aircraft/AircraftCardsSection/styled';

function SpecificationsList({ array, isCarousel, cardsToShow, categoryUrl, language }) {
  
  const check = array.length % 4 == 0;
  const b = Math.ceil(array.length / 4) * 4

  if (check === false) {
    const c = b - array.length;

    const x = [...Array(c).keys()]

    x.map(el => {
        array.push({
    id: "empty"
  })
    })
  }

  const isFR = language === 'fr-fr' || process.env.LANG_CODE === 'fr-fr';

  return (
    <React.Fragment>
      {
        array.slice(0, cardsToShow).map(el => {
          const cardImageUrl = el.cardImage;
          const aircraftUrl = el.aircraftUrl;

          if (el.id !== "empty") {
            return (
              <Card 
                headingText={<h4 style={{textAlign: "left", marginBottom: "0"}}>{el.type}</h4>}
                sizeSmall={true}
                cardImageUrl={cardImageUrl}
                hasSeperator={false}
                language={language ? language : "en-gb"}
                desktopOneRowCard={false}
                isCardLink={true}
                className="aircraft-card"
                cardLink={`${categoryUrl}/${aircraftUrl}`}
                isCarousel={isCarousel}
                hideImageInMobile={false}
                cardList={
                  <StyledAircraftSpecsList>
                    <li>
                      <span><PassengerIcon /></span>
                      {el.passengers} {isFR ? "SIÈGES" : "PASSENGERS"}
                    </li>
                    <li>
                      <span><AircraftIcon /></span>
                      {isFR ? "VITESSE:" : "SPEED:"} {el.speed}KTS
                    </li>
                    <li>
                      <span><LanguageIcon /></span>
                      {isFR ? "PORTÉE:" : "RANGE:"} {el.range}NM
                    </li>
                  </StyledAircraftSpecsList>
                }
              />
            )
          } else {
            return (
              <Card 
                sizeSmall={true}
                cardImageUrl={cardImageUrl}
                hasSeperator={false}
                desktopOneRowCard={false}
                isCardLink={true}
                className="aircraft-card"
                cardLink={`${categoryUrl}`}
                isCarousel={isCarousel}
                hideImageInMobile={false}
                isFakeCard={true}
              />
            )
          }
        })
      }
    </React.Fragment>
  )
}

SpecificationsList.propTypes = {
  array: PropTypes.array,
  cardsToShow: PropTypes.number,
  categoryUrl: PropTypes.string,
  isCarousel: PropTypes.bool,
  language: PropTypes.string,
}

export default SpecificationsList

